import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Embed from 'react-song-embed';
import { Button } from 'theme-ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`404`}</h1>
    <iframe width="560" height="315" style={{
      "marginBottom": "20px"
    }} src="https://www.youtube-nocookie.com/embed/dQw4w9WgXcQ?controls=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <Button as="a" href="/" mdxType="Button">
  View all posts →
    </Button>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      